import React, { useEffect, useState } from 'react';
import { FaSpinner, FaLink, FaPhone, FaMapMarkerAlt, FaSort, FaHome } from 'react-icons/fa';
import Papa from 'papaparse';

const CSV_URL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTtYz7p-y6ip5XwLAlxyAwxqvMewLz80xSXVj721yp-b1SxCUoE6KXxDacx4gOA0X5bM4zzYd6S53d6/pub?gid=0&single=true&output=csv';

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <FaSpinner className="animate-spin text-4xl" />
  </div>
);

const DataCell = ({ children, className }) => (
  <td className={`border border-gray-300 px-4 py-2 text-gray-700 ${className}`}>{children}</td>
);

const DataTable = ({ data, onSort, sortColumn, sortDirection }) => {
  const headers = [
    { label: "Name", key: "name" },
    { label: "Type", key: "type" },
    { label: "Website", key: "website" },
    { label: "Verified Phone Number", key: "verifiedPhoneNumber" },
    { label: "Google Maps Link", key: "googleMapsLink" },
    { label: "Min Cost Per Month", key: "minCostPerMonth" },
    { label: "Max Cost Per Month", key: "maxCostPerMonth" },
  ];

  const reformattedData = data.map(row => ({
    name: row[0],
    type: row[1],
    website: row[2],
    verifiedPhoneNumber: row[3],
    googleMapsLink: row[4],
    minCostPerMonth: row[5] ? parseFloat(row[5].replace(/[^0-9.-]+/g, "")) : 0,
    maxCostPerMonth: row[6] ? parseFloat(row[6].replace(/[^0-9.-]+/g, "")) : 0,
  }));

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            {headers.map((header, index) => (
              <th key={index} className="border border-gray-300 px-4 py-2 text-left font-medium text-gray-600 cursor-pointer" onClick={() => onSort(index)}>
                {header.label}
                {sortColumn === index && (
                  <FaSort className={`inline ml-1 ${sortDirection === 'asc' ? 'rotate-180' : ''}`} />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reformattedData.map((row, rowIndex) => (
            <tr key={rowIndex} className={`${rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
              <DataCell>{row.name}</DataCell>
              <DataCell>{row.type}</DataCell>
              <DataCell>
                <a href={row.website} className="text-blue-500 hover:underline flex items-center">
                  <FaLink className="mr-1" /> Link
                </a>
              </DataCell>
              <DataCell>
                {row.verifiedPhoneNumber && (
                  <a href={`tel:${row.verifiedPhoneNumber}`} className="text-blue-500 hover:underline flex items-center">
                    <FaPhone className="mr-1" /> Call
                  </a>
                )}
              </DataCell>
              <DataCell>
                {row.googleMapsLink && (
                  <a href={row.googleMapsLink} className="text-blue-500 hover:underline flex items-center">
                    <FaMapMarkerAlt className="mr-1" /> Google Maps
                  </a>
                )}
              </DataCell>
              <DataCell>{row.minCostPerMonth === 0 ? '-' : `$${row.minCostPerMonth.toFixed(2)}`}</DataCell>
              <DataCell>{row.maxCostPerMonth === 0 ? '-' : `$${row.maxCostPerMonth.toFixed(2)}`}</DataCell>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FilterBar = ({ filterText, onFilterChange }) => (
  <div className="mb-4 flex justify-between items-center">
    <input
      type="text"
      placeholder="Filter by name..."
      value={filterText}
      onChange={(e) => onFilterChange(e.target.value)}
      className="border border-gray-300 px-4 py-2 rounded-md"
    />
  </div>
);

const Header = () => (
  <div className="flex justify-center items-center my-6">
    <FaHome className="text-3xl mr-2" />
    <h1 className="text-3xl font-bold">Every Affordable Housing Option in San Francisco</h1>
  </div>
);

export default function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    Papa.parse(CSV_URL, {
      download: true,
      complete: (result) => {
        setData(result.data.slice(1)); // Skip the first row
        setLoading(false);
      },
    });
  }, []);

  const handleSort = (columnIndex) => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
    setSortColumn(columnIndex);

    const sortedData = [...data].sort((a, b) => {
      const aValue = columnIndex >= 5 ? (a[columnIndex] ? parseFloat(a[columnIndex].replace(/[^0-9.-]+/g, "")) : 0) : a[columnIndex];
      const bValue = columnIndex >= 5 ? (b[columnIndex] ? parseFloat(b[columnIndex].replace(/[^0-9.-]+/g, "")) : 0) : b[columnIndex];

      if (aValue < bValue) return newDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return newDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setData(sortedData);
  };

  const filteredData = data.filter(row => row[0].toLowerCase().includes(filterText.toLowerCase()));

  return (
    <>
      <Header />
      <div className="p-4">
        {loading ? <LoadingSpinner /> : (
          <>
            <FilterBar filterText={filterText} onFilterChange={setFilterText} />
            <DataTable data={filteredData} onSort={handleSort} sortColumn={sortColumn} sortDirection={sortDirection} />
          </>
        )}
      </div>
    </>
  );
}
